import React, {useEffect, useState} from "react";
import config from "../config/main.config"
import {deleteDownload, getAllDownloads, uploadDownload, jumpToTop} from "../helper/util";
import userStore from "../components/userManagement/userStore";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";


const showDownloads = (downloads, setFileToDelete) => {
    return downloads.map((download) => (
        <div className={"fourteen wide computer sixteen wide mobile column "} key={download.name} style={{margin: "1rem"}}>
            {userStore.isAdmin ? <Button icon={"trash"} color={"red"} onClick={() => {
                setFileToDelete(download.link)
            }} floated={"right"}/> : null}
            <div className={"download-card"}>
                <div>
                    <p style={{fontSize: "20pt", wordBreak: "break-word"}}><b>{download.name}</b></p>
                    <p style={{textTransform: "uppercase"}}>{download.fileType}</p>
                </div>
                <div className={"download-card-container"}>
                    <a href={config.URL + "downloads/" + download.link} rel={"noreferrer"} target={"_blank"}
                       className={"download-card-button"}>
                        <div style={{display: "grid", color: "white"}}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="icon icon-tabler icon-tabler-file-filled margin-auto"
                                 width="50px" height="50px" viewBox="0 0 25 25" strokeWidth="2" stroke="currentColor"
                                 fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path
                                    d="M12 2l.117 .007a1 1 0 0 1 .876 .876l.007 .117v4l.005 .15a2 2 0 0 0 1.838 1.844l.157 .006h4l.117 .007a1 1 0 0 1 .876 .876l.007 .117v9a3 3 0 0 1 -2.824 2.995l-.176 .005h-10a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-14a3 3 0 0 1 2.824 -2.995l.176 -.005h5z"
                                    strokeWidth="0" fill="currentColor"></path>
                                <path d="M19 7h-4l-.001 -4.001z" strokeWidth="0" fill="currentColor"></path>
                            </svg>
                            <p>Vorschau</p>
                        </div>
                    </a>
                    <a href={config.URL + "downloads/" + download.link} download className={"download-card-button"}>
                        <div style={{display: "grid", color: "white"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-download"
                                 width="50px"
                                 height="50px" viewBox="0 0 25 25" strokeWidth="2" stroke="currentColor" fill="none"
                                 style={{margin: "auto"}}
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                                <path d="M7 11l5 5l5 -5"></path>
                                <path d="M12 4l0 12"></path>
                            </svg>
                            <p>Download</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>))
}

const DownloadPage = () => {
    const [downloads, setDownloads] = useState([]);
    const [fileToDelete, setFileToDelete] = useState(null);


    useEffect(() => {
        getAllDownloads().then((download) => setDownloads(download));
         if (typeof window !== "undefined") {
            jumpToTop(window);
        }
    }, []);

    return (
        <div className={'grid ui page-wrapper'}>
        <div className={'row centered'}>
            <h1>Downloads</h1>
        </div>
        {userStore.isAdmin ?
            <div className={"row centered"}>
            <div className={"fourteen wide column"}>
                <p>Datei Hochladen</p>
                <input type={"file"} accept={"application/pdf"}
                       onChange={(event) => uploadDownload(event.target.files).then((download) => {
                           setDownloads(download)
                       })}/>
            </div>

        </div> : null}
        <div className={"row centered"}>
            {showDownloads(downloads, setFileToDelete)}
        </div>
        <Modal open={fileToDelete !== null}>
            <Modal.Header>Datei Löschen?</Modal.Header>
            <Modal.Content><p>Möchten Sie die Datei <b>{fileToDelete}</b> wirklich löschen?</p></Modal.Content>
            <Modal.Actions>
                <Button color={"green"} onClick={() => {
                    deleteDownload(fileToDelete).then((download) => {
                        setDownloads(download)
                        setFileToDelete(null)
                    })
                }}>Ja</Button>
                <Button color={"red"} onClick={() => setFileToDelete(null)}>Nein</Button>
            </Modal.Actions>
        </Modal>
    </div>)
}

export default DownloadPage;